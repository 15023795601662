<template>
  <step-lesson-layout :title="$t('module3.part3.lesson1.title')">
    <template v-slot:content>
      <!-- BLOCK 1 - prerequis -->
      <div class="lesson-block">
        <div class="title-wrapper">
          <p class="label">{{ $t('module3.part3.lesson1.block1.label') }}</p>
          <p class="title">{{ $t('module3.part3.lesson1.block1.title') }}</p>
        </div>
        <p class="paragraphe" v-html="$t('module3.part3.lesson1.block1.content')"></p>
        <div class="check-list">
          <div class="check-group">
            <app-icon-block type="check" :content="$t('module3.part3.lesson1.block1.check1')"></app-icon-block>
          </div>
          <div class="check-group">
            <app-icon-block type="check" :content="$t('module3.part3.lesson1.block1.check2')"></app-icon-block>
            <app-icon-block type="warning" :content="$t('module3.part3.lesson1.block1.warning')"></app-icon-block>
          </div>
        </div>
      </div>

      <!-- BLOCK 2 - step 1 -->
      <div class="lesson-block">
        <div class="flex-wrapper title-wrapper">
          <img :src="require('@/assets/module3/part3/etape1.svg')" alt="step1">
          <div>
            <p class="label">{{ $t('module3.part3.lesson1.step1.label') }}</p>
            <p class="title" style="word-break: break-word">{{ $t('module3.part3.lesson1.step1.title') }}</p>
          </div>
        </div>
        <p class="paragraphe" v-html="$t('module3.part3.lesson1.step1.content')"></p>
        <div class="grid-wrapper">
          <div>
            <drawer class="app-text-drawer">
              <template v-slot:head="{ isOpen }">
                <div class="app-text-drawer-head" :class="{ open: isOpen }">
                  {{ $t('module3.part3.lesson1.step1.drawerHead1') }}
                </div>
              </template>
              <template v-slot:content>
                <div class="app-text-drawer-content">
                  <p class="paragraphe" v-html="$t('module3.part3.lesson1.step1.drawerContent1')"></p>
                </div>
              </template>
            </drawer>
          </div>
          <div>
            <drawer class="app-text-drawer">
              <template v-slot:head="{ isOpen }">
                <div class="app-text-drawer-head" :class="{ open: isOpen }">
                  {{ $t('module3.part3.lesson1.step1.drawerHead2') }}
                </div>
              </template>
              <template v-slot:content>
                <div class="app-text-drawer-content">
                  <p class="paragraphe" v-html="$t('module3.part3.lesson1.step1.drawerContent2')"></p>
                </div>
              </template>
            </drawer>
          </div>
        </div>
      </div>

      <!-- BLOCK 2 - step 2 -->
      <div class="lesson-block">
        <div class="flex-wrapper title-wrapper">
          <img :src="require('@/assets/module3/part3/etape2.svg')" alt="step2">
          <div>
            <p class="label">{{ $t('module3.part3.lesson1.step2.label') }}</p>
            <p class="title">{{ $t('module3.part3.lesson1.step2.title') }}</p>
          </div>
        </div>
        <p class="paragraphe" v-html="$t('module3.part3.lesson1.step2.content')"></p>
        <drawer class="app-text-drawer">
          <template v-slot:head="{ isOpen }">
            <div class="app-text-drawer-head" :class="{ open: isOpen }">
              {{ $t('module3.part3.lesson1.step2.drawerHead') }}
            </div>
          </template>
          <template v-slot:content>
            <div class="app-text-drawer-content">
              <p class="paragraphe" v-html="$t('module3.part3.lesson1.step2.drawerContent')"></p>
              <div class="training-warning align-center">
                <img :src="require('@/assets/icons/warning.svg')" :alt="$t('global.imageAlts.warning')">
                <p>{{ $t('module3.part3.lesson1.step2.drawerContentWarning') }}</p>
              </div>
              <br>
              <p class="paragraphe" v-html="$t('module3.part3.lesson1.step2.drawerContent2')"></p>
              <p>{{ $t('module3.part3.lesson1.step2.drawerContent3') }}</p>
            </div>
          </template>
        </drawer>
        <app-icon-block :content="$t('module3.part3.lesson1.step2.info')"></app-icon-block>
      </div>

      <!-- BLOCK 2 - step 3 -->
      <div class="lesson-block">
        <div class="flex-wrapper title-wrapper">
          <img :src="require('@/assets/module3/part3/etape3.svg')" alt="step2">
          <div>
            <p class="label">{{ $t('module3.part3.lesson1.step3.label') }}</p>
            <p class="title">{{ $t('module3.part3.lesson1.step3.title') }}</p>
          </div>
        </div>
        <p class="paragraphe" v-html="$t('module3.part3.lesson1.step3.content')"></p>
      </div>

      <!-- BLOCK 2 - step 4 -->
      <div class="lesson-block">
        <div class="flex-wrapper title-wrapper">
          <img :src="require('@/assets/module3/part3/etape4.svg')" alt="step2">
          <div>
            <p class="label">{{ $t('module3.part3.lesson1.step4.label') }}</p>
            <p class="title">{{ $t('module3.part3.lesson1.step4.title') }}</p>
          </div>
        </div>
        <p class="paragraphe" v-html="$t('module3.part3.lesson1.step4.content')"></p>
        <app-icon-block :content="$t('module3.part3.lesson1.step4.info')"></app-icon-block>
      </div>

      <!-- BLOCK 2 - step 5 -->
      <div class="lesson-block">
        <div class="flex-wrapper title-wrapper">
          <img :src="require('@/assets/module3/part3/etape5.svg')" alt="step2">
          <div>
            <p class="label">{{ $t('module3.part3.lesson1.step5.label') }}</p>
            <p class="title">{{ $t('module3.part3.lesson1.step5.title') }}</p>
          </div>
        </div>
        <p class="paragraphe" v-html="$t('module3.part3.lesson1.step5.content')"></p>
      </div>

      <!-- BLOCK 2 - step 6 -->
      <div class="lesson-block">
        <div class="flex-wrapper title-wrapper">
          <img :src="require('@/assets/module3/part3/etape6.svg')" alt="step2">
          <div>
            <p class="label">{{ $t('module3.part3.lesson1.step6.label') }}</p>
            <p class="title">{{ $t('module3.part3.lesson1.step6.title') }}</p>
          </div>
        </div>
        <p class="paragraphe" v-html="$t('module3.part3.lesson1.step6.content')"></p>
        <br>
        <p class="paragraphe" v-html="$t('module3.part3.lesson1.step6.content2')"></p>
        <div class="step-tabs-wrapper">
          <app-tabs :tabs="step6Tabs" class="image-tabs-wrapper">
            <template v-slot:tab="{ name, image, isCurrent }">
              <div class="image-tab" :class="{ current: isCurrent }">
                <img :src="image" :alt="name">
              </div>
            </template>
            <template v-slot:content="{ name }">
              <div v-if="name === 'tab1'" class="tab-content image-tab-content">
                <p class="tab-title">{{ $t('module3.part3.lesson1.step6.tab1.title') }}</p>
                <p class="paragraphe" v-html="$t('module3.part3.lesson1.step6.tab1.content')"></p>
                <app-icon-block :content="$t('module3.part3.lesson1.step6.tab1.info')"></app-icon-block>
              </div>
              <div v-else class="tab-content image-tab-content">
                <p class="tab-title">{{ $t('module3.part3.lesson1.step6.tab2.title') }}</p>
                <p class="paragraphe" v-html="$t('module3.part3.lesson1.step6.tab2.content')"></p>
              </div>
            </template>
          </app-tabs>
        </div>
      </div>

      <!-- BLOCK 2 - step 7 -->
      <div class="lesson-block">
          <div class="flex-wrapper title-wrapper">
            <img :src="require('@/assets/module3/part3/etape7.svg')" alt="step2">
            <div>
              <p class="label">{{ $t('module3.part3.lesson1.step7.label') }}</p>
              <p class="title">{{ $t('module3.part3.lesson1.step7.title') }}</p>
            </div>
          </div>
          <p class="paragraphe" v-html="$t('module3.part3.lesson1.step7.content')"></p>
        </div>

      <!-- RESUME BUTTON -->
      <div class="buttons-wrapper">
        <app-button-layout @click="$emit('next-tab')">{{ $t('global.navigation.resume') }}</app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import AppIconBlock from '@/components/layout/AppIconBlock'
import Drawer from '@/components/Drawer'
import AppTabs from '@/components/AppTabs'

export default {
  name: 'Module3Part3Lesson1',
  components: { AppTabs, Drawer, AppIconBlock, AppButtonLayout, StepLessonLayout },
  data () {
    return {
      step6Tabs: [
        {
          name: 'tab1',
          image: require('@/assets/module3/part3/step6tab1.svg')
        },
        {
          name: 'tab2',
          image: require('@/assets/module3/part3/step6tab2.svg')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../styles/module3Lesson';
.lesson-block {
  .title-wrapper {
    text-align: left;
    .label {
      font-size: $fz-xs;
      font-weight: $fw-l;
      text-transform: uppercase;
    }
    .title {
      font-size: $fz-xl;
      text-align: left;
    }
  }
  .app-text-drawer + .app-text-drawer {
    margin-top: $space-s;
  }
  .app-text-drawer + .app-icon-block {
    margin-top: $space-m;
  }
  .image-tabs-wrapper {
    .image-tab {
      height: 160px;
    }
    .tab-content {
      padding: $space-s;
      .tab-title {
        color: $c-secondary;
        font-family: $ff-big;
        font-size: $fz-xl;
        font-weight: $fw-l;
      }
      .paragraphe {
        margin-top: $space-s;
        color: $c-text-dark;
        font-family: $ff;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $space-s;
    .app-text-drawer + .app-text-drawer {
      margin-top: 0;
    }
  }
}
</style>

<style lang="scss">
.step-tabs-wrapper {
  .app-tabs {
    .tabs-wrapper .tabs {
      display: grid;
      gap: 2px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
